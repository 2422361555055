import _log from "utilise/log";
import _client from "utilise/client";
import _merge from "utilise/merge";
import _attr from "utilise/attr";
import _key from "utilise/key";
import _is from "utilise/is";
import _lo from "utilise/lo";
import _fn from "utilise/fn";
var exports = {};

exports = function (ripple, {
  dir = "."
} = {}) {
  return log("creating"), ripple.require = res => module => {
    if (module in res.headers.dependencies && ripple.resources[res.headers.dependencies[module]]) return ripple(res.headers.dependencies[module]);
    throw new Error(`Cannot find module: ${module} for ${res.name}`);
  }, ripple.types["application/javascript"] = {
    header: header,
    selector: res => `${res.name},[is~="${res.name}"]`,
    extract: el => (attr("is")(el) || "").split(" ").concat(lo(el.nodeName)),
    ext: "*.js",
    shortname: path => basename(path).split(".").slice(0, -1).join("."),
    check: res => is.fn(res.body),
    load: !1,
    parse: res => {
      if ("cjs" == res.headers.format) {
        const m = {
          exports: {}
        };
        res.body(m, m.exports, ripple.require(res), {
          env: {}
        }), res.body = m.exports;
      }

      return res;
    }
  }, ripple;
};

const bresolve = (module, parent) => resolve.sync(module, {
  filename: parent
}),
      log = _log("[ri/types/fn]"),
      client = _client,
      merge = _merge,
      attr = _attr,
      key = _key,
      is = _is,
      lo = _lo,
      fn = _fn,
      header = "application/javascript";

var relative, basename, resolve, file, values, keys;
export default exports;